import React, { useContext, useEffect, useState } from "react";
import AppRouter from "./routes/AppRouter";
import Header from "./publickArticle/components/Header/Header";
import Category from "./publickArticle/components/Header/Category";
import { useLocation } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import ModalBanners from "./publickArticle/components/ModalBanners/ModalBanners";
import { Context } from "./index";

const PublickPanel: React.FC = () => {
 const locate = useLocation();
 const { storeblog } = useContext(Context);

 const [showModal, setShowModal] = useState(false);

 useEffect(() => {
  const fetchPopupBanners = async () => {
   await storeblog.GetPopupBanners();

   if (storeblog.popupBanners.isActive) {
    const delay = parseInt(storeblog.popupBanners.showTime, 10) || 0;

    const timer = setTimeout(() => {
     setShowModal(true);
    }, delay);

    return () => clearTimeout(timer);
   } else {
    setShowModal(false);
   }
  };

  fetchPopupBanners();
  storeblog.getUserWalker();
 }, []);

 const closeModal = () => {
  setShowModal(false);
 };

 return (
  <>
   {locate.pathname !== "/login" ? (
    <div>
     <Header />
     <Category />
     <AppRouter />
     {showModal && <ModalBanners onClick={closeModal} />}
    </div>
   ) : (
    <AuthPage />
   )}
  </>
 );
};

export default PublickPanel;
