import React, { FC, useContext } from "react";

import { observer } from "mobx-react-lite";
import "./css/PopupModalBanner.css";
import { Context } from "../../index";

interface ModalProps {
 actionShowPopup: () => void;
 actionEditBanner: (id: Array<string>) => void;
}

const PopupModalBanner: FC<ModalProps> = (props) => {
 const { postStore } = useContext(Context);

 const hendlerActionModal = (id: string) => {
  props.actionEditBanner([id]);
 };

 return (
  <div
   className="modal show"
   onClick={() => {
    props.actionShowPopup();
   }}
  >
   <div
    className="modal-dialog"
    onClick={(e) => {
     e.stopPropagation();
    }}
   >
    <div className="modal-content">
     <div
      className=" d-flex flex-column align-items-center w-100 rounded"
      style={{ boxShadow: "0 0 12px 4px #5c5c5c" }}
     >
      <div className="modal-header d-flex align-item-center justify-content-space w-100 p-2 px-3">
       <h4 className="m-0">Add banners</h4>
       <button
        type="button"
        className="btn btn-dark btn-sm active border border-white "
        onClick={() => {
         props.actionShowPopup();
        }}
       >
        <span aria-hidden="true">&times;</span>
       </button>
      </div>
      <ul
       style={{ height: "calc(100vh - 166px)", margin: 0 }}
       className="d-flex flex-column align-items-center overflow-y-scroll p-0 pt-4 pb-4"
      >
       {postStore.banners.map((e, i) => {
        return (
         <li
          key={e._id}
          className="list-unstyled"
          onClick={() => hendlerActionModal(e._id)}
         >
          <div className={`popup_modal_banner_item_box `}>
           <div
            className="popup_modal_banner_item_img"
            style={{
             background: `url(${e.mainImg}) center/cover no-repeat ,rgba(109, 180, 242, 0)`,
            }}
           ></div>
           <p className="popup_modal_banner_item_text">{e.title}</p>
          </div>
         </li>
        );
       })}
      </ul>
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(PopupModalBanner);
