import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MobNav from "./MobNav";
import { RoutesList } from "../../../routes";
import logo from "../../images/logo.png";

const Header = () => {
 const { storeblog } = useContext(Context);
 const location = useLocation();
 const logo = require("../../images/logo.png");
 const limit = 10;

 const [isMobNavShow, setIsMobNavShow] = useState(false);

 const handleToggleMobNav = () => {
  setIsMobNavShow(!isMobNavShow);
 };

 const ImgClicked = () => {
  storeblog.setPages(1);
  if (location.pathname === "/" && storeblog.selectedCategory === "") {
   storeblog.GetPosts(storeblog.pages, limit, "");
  }
  storeblog.setSelectedCategory("");
 };

 return (
  <header>
   {isMobNavShow ? <MobNav handleToggleMobNav={handleToggleMobNav} /> : ""}
   <div className="middle-section py-3">
    <div className="container">
     <div className="w-100 d-flex justify-content-between align-items-center">
      <Link to={RoutesList.MAIN_ROUTER}>
       <div className="header_logo" onClick={ImgClicked}>
        <img src={logo} alt="" />
       </div>
      </Link>
      <div className="text-white d-flex justify-content-end">
       <div className="mob_nav_box">
        <button onClick={handleToggleMobNav} className="mob_nav_btn">
         <FontAwesomeIcon icon={faBars} />
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>
  </header>
 );
};

export default Header;
