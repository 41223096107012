import React, { FC, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import Editoor from "../Posts/Editor/Editoor";
import { useFetching } from "../../lib/useFetching";
import Loading from "../UI/Loading/Loading";

interface EditBannerModalProps {
 bannerId: string;
 post: string;
 title: string;
 isActive: boolean;
 priority: string;
 description: string;
 link: string;
 handlePopUpOpen: () => void;
}

const EditBannerModal: FC<EditBannerModalProps> = (props) => {
 const { postStore } = useContext(Context);
 const [isActive, setIsActive] = useState<boolean>(props.isActive);

 const checkScriptAvailible = (value: string | undefined) => {
  let scriptReg = /<script/;
  return !scriptReg.test(value || "");
 };

 const setArticleIsActive = () => {
  setIsActive(!isActive);
 };

 const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (/^\d*$/.test(e.target.value)) {
   formik.setFieldValue("priority", e.target.value);
  }
 };

 const handleEditHtml = (html: string) => {
  formik.setFieldValue("editorHtml", html);
 };

 const Close = () => {
  props.handlePopUpOpen();
 };

 const EditBanner = async () => {
  const thisDate = new Date();

  const id = props.bannerId;
  const date = thisDate.toLocaleDateString();
  const time = thisDate.toLocaleTimeString();

  const post = formik.values.editorHtml;
  const link = formik.values.bannerLink;
  const title = formik.values.title;
  const priority = formik.values.priority;

  const response = await postStore.editBanner(
   id,
   date,
   time,
   isActive,
   title.trim(),
   title.trim(),
   post,
   link.trim(),
   priority
  );
  props.handlePopUpOpen();
  postStore.getBanners(1, 20);
 };

 const bannerValidationSchema = Yup.object({
  title: Yup.string()
   .test(
    "scriptAvailible",
    "Поле містить заборонені символи",
    checkScriptAvailible
   )
   .required(`Поле обов'язкове до заповнення `),
  bannerLink: Yup.string()
   .test(
    "scriptAvailible",
    "Поле містить заборонені символи",
    checkScriptAvailible
   )
   .required(`Поле обов'язкове до заповнення `),
  priority: Yup.string().required(`Поле обов'язкове до заповнення `),
  editorHtml: Yup.string()
   .required(`Поле обов'язкове до заповнення `)
   .test(
    "editorCheck",
    "У полі не повинно бути нічого крім зображення",
    (value) =>
     (value
      ?.replace(/<[^>]*?>/gi, "")
      .replace(/\s{1,}|\n{1,}/, "")
      .trim().length || 0) === 0
   )
   .test(
    "scriptAvailible",
    "Поле містить заборонені символи",
    checkScriptAvailible
   )
   .test(
    "editorImgAvailible",
    "Поле може мати xоча б одне зображення",
    (value) => (value?.match(/<img/gi)?.length || 0) !== 0
   )
   .test(
    "editorImgCount",
    "Поле може мати тільки одне зображення",
    (value) => (value?.match(/<img/gi)?.length || 0) <= 1
   ),
 });

 const formik = useFormik({
  initialValues: {
   title: props.title,
   bannerLink: props.link,
   priority: props.priority,
   editorHtml: props.post,
  },
  validationSchema: bannerValidationSchema,
  onSubmit: (values) => {
   fetching();
  },
 });

 const { fetching, isError, isLoading } = useFetching(EditBanner);

 return (
  <div className="modal show edit_post" role="dialog">
   {isLoading && <Loading />}
   <div className="modal-dialog" role="document">
    <div className="modal-content p-4">
     <div className="d-flex flex-column align-items-start">
      <h3>Editing a banner</h3>
      <div className="h-9 w-100">
       <MyInput
        type="text"
        id={"1"}
        value={formik.values.title}
        name="title"
        placeholder={"Title"}
        icon={"text"}
        void={formik.handleChange}
       />
       {formik.touched.title && formik.errors.title && (
        <div className="input_error mt-1 px-2 py-1">{formik.errors.title}</div>
       )}
      </div>
      <div className="pt-3 w-100">
       <MyInput
        type="text"
        id={"2"}
        value={formik.values.bannerLink}
        name="bannerLink"
        placeholder={"Link to the offer"}
        icon={"link-alt"}
        void={formik.handleChange}
       />
       {formik.touched.bannerLink && formik.errors.bannerLink && (
        <div className="input_error mt-1 px-2 py-1">
         {formik.errors.bannerLink}
        </div>
       )}
      </div>
      <div className="input-group">
       <MyInput
        type={"text"}
        id="3"
        value={formik.values.priority}
        name="priority"
        placeholder={"Priority (Від 1, 1 - top priority)"}
        void={handleChoosePriority}
       />
       {formik.touched.priority && formik.errors.priority && (
        <div className="input_error mt-1 px-2 py-1">
         {formik.errors.priority}
        </div>
       )}
      </div>
     </div>
     <div className="h-9 pt-4">
      <div className="form-check">
       <input
        className={"form-check-input " + (isActive && "bg-black border-black")}
        onChange={setArticleIsActive}
        checked={isActive}
        type="checkbox"
        value=""
        id="flexCheckDefault2"
       />
       <label className="form-check-label" htmlFor="flexCheckDefault2">
        Show
       </label>
      </div>
     </div>
     <div className="h-80 bg-white text-black">
      <Editoor
       editorHtml={formik.values.editorHtml}
       setEditorHtml={handleEditHtml}
       placeholder="Write here..."
      />
     </div>
     {formik.touched.editorHtml && formik.errors.editorHtml && (
      <div className="input_error mt-1 px-2 py-1">
       {formik.errors.editorHtml}
      </div>
     )}

     <div className="flex justify-between w-75 pt-4">
      <button onClick={Close} className="btn btn-light rounded-md w-25">
       Back
      </button>
      <button
       onClick={() => formik.handleSubmit()}
       className="btn btn-secondary rounded-md mx-2 w-25"
       disabled={isLoading}
      >
       Save
      </button>
     </div>
    </div>
   </div>
  </div>
 );
};

export default EditBannerModal;
