import React, { FC, useContext, useState } from "react";
import { Context } from "./index";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import "./publickArticle/css/style.css";
import "./publickArticle/css/custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthPanel from "./AuthPanel";
import Loading from "./components/UI/Loading/Loading";
import MyModal from "./components/UI/MyModal/MyModal";
import "./components/CSS/css/app.css";
import "./components/CSS/css/icons.css";
import "./components/CSS/css/header-colors.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/CSS/css/dark-theme.css";
import "./components/CSS/css/pace.min.css";
import PublickPanel from "./PublickPanel";

const App: FC = () => {
 const location = useLocation();
 const { store } = useContext(Context);

 const [visible, setVisible] = useState(true);
 let htmlRoot = document.getElementsByTagName("html");
 if (location.pathname.startsWith("/admin/")) {
  htmlRoot[0].classList.add("dark-theme");
 }

 //
 return (
  <div className="col">
   {store.isLoading && <Loading />}
   {store.isError !== "" && (
    <MyModal
     visible={visible}
     void={() => setVisible(false)}
     description={store.isError}
    />
   )}
   {location.pathname.startsWith("/admin/") ? <AuthPanel /> : <PublickPanel />}
  </div>
 );
};

export default observer(App);
