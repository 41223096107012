import React, { FC, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Editoor from "./Editor/Editoor";
import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import { RoutesList } from "../../routes";
import { useFetching } from "../../lib/useFetching";
import Loading from "../UI/Loading/Loading";

const CreatePost: FC = () => {
 const { store } = useContext(Context);
 const { postStore } = useContext(Context);
 const navigate = useNavigate();

 const [isTop, setIsTop] = useState<boolean>(false);
 const [isShowCategory, setIsShowCategory] = useState<any>(false);
 const [categoryPost, setCategoryPost] = useState<any>("FINANCE");

 const handleShowCategories = () => {
  setIsShowCategory(!isShowCategory);
 };
 const handleChooseCategories = (e: any) => {
  setCategoryPost(e.target.innerText);
  setIsShowCategory(!isShowCategory);
 };
 const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (/^\d*$/.test(e.target.value)) {
   formik.setFieldValue("priority", e.target.value);
  }
 };
 const handleEditHtml = (html: string) => {
  formik.setFieldValue("editorHtml", html);
 };

 const checkScriptAvailible = (value: string | undefined) => {
  let scriptReg = /<script/;
  return !scriptReg.test(value || "");
 };
 const CreatePost = async () => {
  const thisDate = new Date();

  const date = thisDate.toLocaleDateString();
  const time = thisDate.toLocaleTimeString();

  const type = isTop;
  const post = formik.values.editorHtml;
  const title = formik.values.title;
  const priority = formik.values.priority;
  const category = categoryPost;

  const response = await postStore.addPost(
   date,
   time,
   type,
   title,
   title,
   category,
   post,
   priority
  );
  navigate(RoutesList.POSTS_LIST_ROUTER);
  store.setSideBarActiveID(0);
 };
 const bannerValidationSchema = Yup.object({
  title: Yup.string()
   .test(
    "scriptAvailible",
    "The field contains prohibited characters",
    checkScriptAvailible
   )
   .required(`This field is required `),
  priority: Yup.string().required(`This field is required `),
  editorHtml: Yup.string()
   .required(`This field is required `)
   .test(
    "editorCheck",
    "The field must have some text",
    (value) =>
     (value
      ?.replace(/<[^>]*?>/gi, "")
      .replace(/\s{1,}|\n{1,}/, "")
      .trim().length || 0) !== 0
   )
   .test(
    "scriptAvailible",
    "The field contains prohibited characters",
    checkScriptAvailible
   ),
 });
 const formik = useFormik({
  initialValues: {
   title: "",
   priority: "",
   editorHtml: "",
  },
  validationSchema: bannerValidationSchema,
  onSubmit: (values) => {
   fetching();
  },
 });

 const { fetching, isError, isLoading } = useFetching(CreatePost);

 return (
  <div className="p-4 bg-light rounded-md ">
   {isLoading && <Loading />}
   <div className="flex justify-between pb-4">
    <div className="w-[500px] flex justify-between">
     <h3>Create a post</h3>
     <div className="h-9">
      <MyInput
       type="text"
       id={"1"}
       value={formik.values.title}
       name="title"
       placeholder={"Title"}
       icon={"text"}
       void={formik.handleChange}
      />
     </div>
     {formik.touched.title && formik.errors.title && (
      <div className="input_error mt-1 px-2 py-1">{formik.errors.title}</div>
     )}
     <div className="pt-3">
      <p className="text-uppercase mb-1 text-secondary">Category</p>
      <div className="input-group">
       <button
        className="btn btn-outline-secondary dropdown-toggle w-50"
        onClick={handleShowCategories}
       >
        {categoryPost}
       </button>
       <ul className={"dropdown-menu w-50 " + (isShowCategory && "show")}>
        <li
         className="dropdown-item"
         onClick={(e) => handleChooseCategories(e)}
        >
         FINANCE
        </li>
        <li
         className="dropdown-item"
         onClick={(e) => handleChooseCategories(e)}
        >
         HEALTH
        </li>
        <li
         className="dropdown-item"
         onClick={(e) => handleChooseCategories(e)}
        >
         ANOTHER
        </li>
       </ul>
      </div>
     </div>
     <div className="pt-3">
      <div className="input-group">
       <MyInput
        type={"text"}
        id={"2"}
        value={formik.values.priority}
        name="priority"
        placeholder={"Priority"}
        void={handleChoosePriority}
       />
      </div>
     </div>
     {formik.touched.priority && formik.errors.priority && (
      <div className="input_error mt-1 px-2 py-1">{formik.errors.priority}</div>
     )}
    </div>
    <div className="h-9 pt-4">
     <div className="form-check">
      <input
       className={"form-check-input " + (isTop && "bg-black border-black")}
       onChange={() => setIsTop(!isTop)}
       type="checkbox"
       value=""
       id="flexCheckDefault"
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
       Add to Top
      </label>
     </div>
    </div>
   </div>
   <div className="h-80 bg-white text-black">
    <Editoor
     editorHtml={formik.values.editorHtml}
     setEditorHtml={handleEditHtml}
     placeholder="Write here..."
    />
   </div>
   {formik.touched.editorHtml && formik.errors.editorHtml && (
    <div className="input_error mt-1 px-2 py-1">{formik.errors.editorHtml}</div>
   )}
   <div className="flex justify-between w-full pt-4">
    <button
     onClick={() => formik.handleSubmit()}
     className="btn btn-secondary rounded-md"
     disabled={isLoading}
     type="submit"
    >
     Add a post
    </button>
   </div>
  </div>
 );
};

export default observer(CreatePost);
