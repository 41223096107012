import { makeAutoObservable } from "mobx";
import { IBanner, IPopupBannersList, IPost } from "../models/IPost";
import PostService from "../services/PostService";
import OneSignalService from "../services/OneSignalService";
import { ISignal } from "../models/ISignal";
import {
 PopupBannersListID,
 PopupBannersResponse,
} from "../models/response/PopupBanners";

export default class PostStore {
 posts = [] as IPost[];
 banners = [] as IBanner[];
 pages: number = 1;
 bannerPages: number = 1;
 signalToken = [] as ISignal[];
 popupBanners = [] as PopupBannersResponse[];
 popupBannersListID = [] as object[];

 isLoading = false;
 isError = "";

 constructor() {
  makeAutoObservable(this);
 }

 setPosts(post: IPost[]) {
  this.posts = post;
 }
 setBanners(banner: IBanner[]) {
  this.banners = banner;
 }

 setPages(pages: number) {
  this.pages = pages;
 }
 setBannerPages(pages: number) {
  this.bannerPages = pages;
 }

 setPopupBannersListID(arr: object[]) {
  this.popupBannersListID = arr;
 }
 setPopupBannersList(arr: PopupBannersResponse[]) {
  this.setPopupBannersListID(arr);
  this.popupBanners = arr;
 }
 setIsLoading(bool: boolean) {
  this.isLoading = bool;
 }

 setIsError(error: string) {
  this.isError = error;
 }

 setSignalToken(token: ISignal[]) {
  this.signalToken = token;
 }

 async addPost(
  date: string,
  time: string,
  isTop: boolean,
  title: string,
  description: string,
  category: string,
  post: string,
  priority: string
 ) {
  try {
   const newPost = await PostService.addPost(
    date,
    time,
    isTop,
    title,
    description,
    category,
    post,
    Number(priority)
   );
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async editPost(
  id: string,
  date: string,
  time: string,
  isActive: boolean,
  title: string,
  description: string,
  category: string,
  post: string,
  priority: string
 ) {
  try {
   const newPost = await PostService.editPost(
    id,
    date,
    time,
    isActive,
    title,
    description,
    category,
    post,
    Number(priority)
   );
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async getPosts(page: number, limit: number) {
  try {
   const response = await PostService.getPosts(page, limit);
   const posts = response.data.posts;
   const pages = response.data.totalPages;
   this.setPosts(posts);
   this.setPages(pages);
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async addBanner(
  date: string,
  time: string,
  title: string,
  description: string,
  post: string,
  link: string,
  priority: string
 ) {
  try {
   const newBanner = await PostService.addBanner(
    date,
    time,
    title,
    description,
    post,
    link,
    Number(priority)
   );
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async editBanner(
  id: string,
  date: string,
  time: string,
  isActive: boolean,
  title: string,
  description: string,
  post: string,
  link: string,
  priority: string
 ) {
  try {
   const newPost = await PostService.editBanner(
    id,
    date,
    time,
    isActive,
    title,
    description,
    post,
    link,
    Number(priority)
   );
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async getBanners(page: number, limit: number) {
  try {
   const response = await PostService.getBanners(page, limit);
   const banners = response.data.banner;
   const pages = response.data.totalPages;
   this.setBanners(banners);
   this.setBannerPages(pages);
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async getSignalToken() {
  try {
   const response = await OneSignalService.getSignalToken();
   const token = response.data;
   this.setSignalToken(token);
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async editSignalToken(id: string, signalToken: string) {
  try {
   const newToken = await OneSignalService.editSignalToken(id, signalToken);
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async getPopupBanners() {
  try {
   const popupBannersList = await PostService.getPopupBanners();
   const data = popupBannersList.data;
   this.setPopupBannersList(data);
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }

 async updatePopupBanners(popupBannersList: object[]) {
  try {
   const updatePopupBannersList = await PostService.updatePopupBanners(
    popupBannersList
   );
  } catch (e: any) {
   this.setIsError(e.response?.data?.message);
  }
 }
}
