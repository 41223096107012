import React, { useContext, useEffect, useState, useRef } from "react";
import "../Posts/ArticleItem.css";
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";

const BannerItem = ({
 bannerID,
 title,
 link,
 photo,
 articleSeoName,
 bannerSeoName,
 articleId,
 type,
}) => {
 const { storeblog } = useContext(Context);

 const [isVisible, setIsVisible] = useState(false);
 const elementRef = useRef(null);

 const sendTreaking = (action) => {
  type === "modal"
   ? storeblog.getUserAction(bannerID, "modal", action)
   : storeblog.getUserAction(bannerID, articleId, action);
 };

 useEffect(() => {
  const observer = new IntersectionObserver(
   (entries) => {
    entries.forEach((entry) => {
     if (entry.isIntersecting) {
      setIsVisible(true);
      type === "modal"
       ? storeblog.getUserAction(bannerID, "modal", "view")
       : storeblog.getUserAction(bannerID, articleId, "view");
     } else {
      setIsVisible(false);
     }
    });
   },
   {
    threshold: 1,
   }
  );

  if (elementRef.current) {
   observer.observe(elementRef.current);
  }

  return () => {
   if (elementRef.current) {
    observer.unobserve(elementRef.current);
   }
  };
 }, []);

 const updateUtmContent = (urlParams, bannerSeoName, articleSeoName) => {
  let params = new URLSearchParams(urlParams);

  params.set("utm_content", articleSeoName);
  params.set("utm_term", bannerSeoName);

  return `?${params.toString()}`;
 };

 const params = updateUtmContent(
  storeblog.urlParams,
  bannerSeoName,
  articleSeoName
 );
 if (storeblog.userWalker !== "1") {
 } else if (type === "article") {
  return (
   <div
    className="article_banner_box"
    ref={elementRef}
    onClick={() => {
     sendTreaking("click");
    }}
   >
    <a
     href={link + params}
     rel="noreferrer"
     target="_blank"
     className="small-life-divs d-flex"
    >
     <figure>
      <p>
       <img src={photo} alt="" />
      </p>
     </figure>
     <div className="right-test2">
      <h5 className="article_banner_box_info_title">{title}</h5>
      <div className="article_banner_box_info_description">
       <p>[Oficiální webové stránky]</p>
       <svg xmlns="http://www.w3.org/2000/svg" width="22.703" height="21.928">
        <path d="M1.056 21.928c0-6.531 5.661-9.034 10.018-9.375V18.1L22.7 9.044 11.073 0v4.836a10.5 10.5 0 0 0-7.344 3.352C-.618 12.946-.008 21 .076 21.928z"></path>
       </svg>
      </div>
     </div>
    </a>
   </div>
  );
 } else if (type === "modal") {
  return (
   <div
    className="popup_banner_item"
    ref={elementRef}
    onClick={() => {
     sendTreaking("click");
    }}
   >
    <a
     href={link + params}
     rel="noreferrer"
     target="_blank"
     className="popup_banner h-100 d-flex flex-column"
    >
     <img className="popup_banner-img flex-shrink-0" src={photo} alt="" />
     <div className="px-3 flex-grow-1 d-flex flex-column justify-content-between">
      <p className="popup_banner-title py-2">{title}</p>
      <div className="d-flex justify-content-between">
       <p>[Oficiální webové stránky]</p>
       <svg xmlns="http://www.w3.org/2000/svg" width="22.703" height="21.928">
        <path d="M1.056 21.928c0-6.531 5.661-9.034 10.018-9.375V18.1L22.7 9.044 11.073 0v4.836a10.5 10.5 0 0 0-7.344 3.352C-.618 12.946-.008 21 .076 21.928z"></path>
       </svg>
      </div>
     </div>
    </a>
   </div>
  );
 } else {
  return (
   <div
    className="col"
    ref={elementRef}
    onClick={() => {
     sendTreaking("click");
    }}
   >
    <a
     href={link + params}
     rel="noreferrer"
     target="_blank"
     className="article_side_banner"
    >
     <img className="article_side_banner-img" src={photo} alt="banner" />
     <p className="article_side_banner-title">{title}</p>
    </a>
   </div>
  );
 }
};

export default observer(BannerItem);
