import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Context } from "../../../index";
import { RoutesList } from "../../../routes";

const PostItem = ({ post }) => {
 const { storeblog } = useContext(Context);
 const parser = new DOMParser();
 const parsedHtml = parser.parseFromString(post.post, "text/html");
 const [category, setCategory] = useState("Finance");
 const categoriesNames = ["Finance", "Zdraví", "Další"];
 let photo = "";

 const imgTags = parsedHtml.getElementsByTagName("img");
 for (let i = 0; i < imgTags.length; i++) {
  const src = imgTags[0].getAttribute("src");
  photo = src;
 }

 useEffect(() => {
  post.category == "FINANCE"
   ? setCategory(categoriesNames[0])
   : post.category == "HEALTH"
   ? setCategory(categoriesNames[1])
   : post.category == "ANOTHER"
   ? setCategory(categoriesNames[2])
   : setCategory(categoriesNames[2]);
 });
 const handleClick = () => {
  storeblog.setSelectedCategory("");
  window.scrollTo({
   top: 0,
   behavior: "smooth", // для плавного скролу
  });
 };

 return (
  <>
   <Link
    to={RoutesList.POST_ROUTER + "/" + post.seoName + "/" + post._id}
    onClick={handleClick}
    className="col cursor-pointer"
   >
    <div className="col">
     <a href="#" className="populer-sec1-news">
      <figure>
       <img src={post.mainImg} alt="photo" />
       <span className="st-tags"> {category}</span>
       {post.isTop ? (
        <div className="content_isTop">
         <span>Exkluzivní</span>
        </div>
       ) : null}
      </figure>
      <div className="content-div1">
       <h5> {post.title} </h5>
      </div>
     </a>
    </div>
   </Link>
  </>
 );
};

export default observer(PostItem);
