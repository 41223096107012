import React, { useContext, useEffect, useState } from "react";
import Blog from "./Posts/Blog";
import Footer from "./Footer/Footer";
import News from "./Header/News";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import OneSignal from "react-onesignal";

const Main = () => {
 const { storeblog, postStore } = useContext(Context);

 const [initialized, setInitialized] = useState(false);
 useEffect(() => {
  async function fetchBackButtonLink() {
   try {
    await storeblog.GetBackBtnLink();

    setBackButtonLink(JSON.stringify(storeblog.backBtnLink));
   } catch (error) {
    console.error("Error fetching back button link:", error);
   }
  }
  fetchBackButtonLink();

  postStore.getSignalToken();
  const runOneSignal = () => {
   setTimeout(() => {
    if (postStore.signalToken[0].signal) {
     OneSignal.init({ appId: `${postStore.signalToken[0].signal}` }).then(
      () => {
       setInitialized(true);
       OneSignal.Slidedown.promptPush();
      }
     );
    } else {
     runOneSignal();
    }
   }, 1000);
  };
  runOneSignal();
 }, []);

 const setBackButtonLink = (data) => {
  let bbdata = JSON.parse(data);

  if (bbdata[0].backBtnLinkStatus) {
   window.history.pushState({ redirected: false }, "", window.location.href);
   const handleBackButton = () => {
    window.location.href = bbdata[0].backBtnLinkDB;
   };
   window.addEventListener("popstate", handleBackButton);
  } else {
   return false;
  }
 };

 return (
  <div>
   <div className="w-100">
    <News />
   </div>
   <div className="w-100">
    <div className="container mx-auto">
     <Blog />
    </div>
   </div>
   <Footer />
  </div>
 );
};

export default observer(Main);
