import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { RoutesList } from "../../routes";
import SideButton from "../UI/SideButton/SideButton";

const SideBar: FC = () => {
 const { store } = useContext(Context);
 const [page, setPage] = useState(0);

 const sideButtonsArr = [
  {
   text: "Posts List",
   link: `${RoutesList.POSTS_LIST_ROUTER}`,
   icon: "list-ul",
  },
  {
   text: "Banners List",
   link: `${RoutesList.BANNERS_LIST_ROUTER}`,
   icon: "layer",
  },
  {
   text: "Create a post",
   link: `${RoutesList.BLOG_ROUTER}`,
   icon: "receipt",
  },
  {
   text: "Create a banner",
   link: `${RoutesList.CREATE_BANNER_ROUTER}`,
   icon: "mobile-landscape",
  },
  {
   text: "Statistics",
   link: `${RoutesList.BANNER_STATISTIC_ROUTER}`,
   icon: "bar-chart",
  },
  {
   text: "POPUP banners",
   link: `${RoutesList.POPUP_BANNER_ROUTER}`,
   icon: "bookmark-heart",
  },
  {
   text: "BackBtn link",
   link: `${RoutesList.BACK_BTN_URL_ROUTER}`,
   icon: "blanket",
  },
  {
   text: "Edit OS Token",
   link: `${RoutesList.ONE_SIGNAL_EDIT_TOKEN_ROUTER}`,
   icon: "key",
  },
 ];

 useEffect(() => {
  const sideBarPage = localStorage.getItem("sideBarPage");
  sideBarPage ? setPage(+sideBarPage) : setPage(0);
 }, []);

 const navigate = useNavigate();

 const LogOut = () => {
  store.logout().then(() => {
   navigate("/login");
  });
 };

 return (
  <div className="sidebar-wrapper">
   <div className="simplebar-wrapper">
    <div className="simplebar-mask">
     <div className="simplebar-offset">
      <div className="simplebar-content-wrapper">
       <div className="simplebar-content mm-active">
        <div className="sidebar-header">
         <div>
          <Link to={RoutesList.POSTS_LIST_ROUTER}>
           <div className="d-flex justify-center w-full mx-auto">
            <i className="fadeIn animated bx bx-unite"></i>
            <h4 className="text-uppercase m-1">newsboard</h4>
           </div>
          </Link>
         </div>
        </div>
        <ul id="menu" className="metismenu mm-show">
         <li className="menu-label">Menu</li>

         {sideButtonsArr.map((e, i) => {
          return (
           <li key={i}>
            <SideButton
             text={e.text}
             link={e.link}
             icon={e.icon}
             active={store.sideBarActiveID === i} // Передача статусу активності до компонента SideButton
             id={i}
            />
           </li>
          );
         })}
         <li>
          <button
           type="button"
           className="btn btn-dark d-flex bg-transparent border-0"
           aria-expanded="false"
           onClick={LogOut}
          >
           <div className="parent-icon">
            <i className="bx bx-exit"></i>
           </div>
           <div className="menu-title">Exit</div>
          </button>
         </li>
        </ul>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(SideBar);
