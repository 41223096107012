import { AxiosResponse } from "axios";

import $api from "../http";
import { ISignal } from "../models/ISignal";

export default class OneSignalService {
 static async getSignalToken(): Promise<AxiosResponse<ISignal[]>> {
  return $api.get<ISignal[]>("/signal/getSignalToken");
 }
 static async editSignalToken(
  id: string,
  signalToken: string
 ): Promise<AxiosResponse<[]>> {
  return $api.post<[]>("/signal/editSignalToken", { id, signalToken });
 }
}
