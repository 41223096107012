import $api from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";

export default class AuthService {
 static async login(
  username: string,
  password: string
 ): Promise<AxiosResponse<AuthResponse>> {
  return $api.post<AuthResponse>("user/login", { username, password });
 }

 static async registration(
  username: string,
  password: string,
  role: string
 ): Promise<AxiosResponse<AuthResponse>> {
  return $api.post<AuthResponse>("/user/registration", {
   username,
   password,
   role,
  });
 }

 static async logout(): Promise<void> {
  return $api.post("/user/logout");
 }
}
