import { AxiosResponse } from "axios";

import $api from "../http";
import { IBackBtnLink } from "../models/IBackBtnLink";

export default class BackBtnLinkService {
 static async getBackBtnLink(): Promise<AxiosResponse<IBackBtnLink[]>> {
  return $api.get<IBackBtnLink[]>("/backBtn/getBackBtnLink");
 }
 static async editBackBtnLink(
  id: string,
  backBtnLink: string,
  backBtnLinkStatus: boolean
 ): Promise<AxiosResponse<[]>> {
  return $api.post<[]>("/backBtn/editBackBtnLink", {
   id,
   backBtnLink,
   backBtnLinkStatus,
  });
 }
}
