import React, { FC, useContext, useEffect, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import PopupBannerItem from "../components/PopupBannerItem/PopupBannerItem";
import PopupModalBanner from "../components/PopupBannerItem/PopupModalBanner";
import PopupBannerConfirm from "../components/PopupBannerItem/PopupModalConfrim";
import MyInput from "../components/UI/MyInput/MyInput";

import styled from "styled-components";

const PopupBannerList: FC = () => {
 const { postStore, storeblog } = useContext(Context);
 const [storeData, setStoreData] = useState({});

 const [statusEditPage, setStatusEditPage] = useState(false);
 const [statusEditTime, setStatusEditTime] = useState(false);
 const [isShowPopup, setIsShowPopup] = useState(false);
 const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);

 const [statusSave, setStatusSave] = useState(false);

 const [listBanner, setListBanner] = useState<any[]>([]);

 const [chosenBanner, setChoosenBanner] = useState(0);
 const [timeShowPopup, setTimeShowPopup] = useState("1000");

 useEffect(() => {
  // Загружаем данные при первом рендере
  storeblog.GetPopupBanners();
  storeblog.GetBanners(1, 1000);
 }, []);

 useEffect(() => {
  // Когда storeblog.popupBanners обновится, обновляем локальное состояние
  if (storeblog.popupBanners) {
   setStoreData(toJS(storeblog.popupBanners));
  }
 }, [storeblog.popupBanners]); // Ждем, пока данные загрузятся

 useEffect(() => {
  if (!storeData || Object.keys(storeData).length === 0) return; // Проверяем, что данные загружены

  const objMain = Object(toJS(storeData));

  setStatusEditTime(objMain.isActive);
  setTimeShowPopup(objMain.showTime);

  const obj = Object(toJS(storeblog.popupBanners));

  let newListBanner = [];
  if (!obj.popupBannersList || obj.popupBannersList.length === 0) {
   newListBanner = [null, null, null, null, null, null];
  } else {
   for (let i = 0; i < 6; i++) {
    newListBanner.push(
     obj.popupBannersList[i] ? obj.popupBannersList[i] : null
    );
   }
  }
  setListBanner(newListBanner);
 }, [storeData]);

 const changeBanner = (itemNum: number) => {
  document.body.style.overflow = "hidden";
  setIsShowPopup(!isShowPopup);
  setChoosenBanner(itemNum);
 };

 const reloadData = () => {
  const obj = Object(toJS(storeData));
  setStatusEditTime(obj.isActive);
  setTimeShowPopup(obj.showTime);
  postStore.getBanners(1, 1000);
  postStore.getPopupBanners();
 };

 const handleChooseBanner = (ids: string[]) => {
  document.body.style.overflow = "auto";
  setIsShowPopup(false);
  const updatedListBanner = listBanner;
  ids.forEach((bannerId, index) => {
   if (bannerId) {
    const newBanner = postStore.banners.find((b) => b._id === bannerId);
    if (newBanner) {
     updatedListBanner[chosenBanner] = newBanner;
    }
   } else {
    updatedListBanner[chosenBanner] = null;
   }
  });

  setListBanner(updatedListBanner);
 };

 const validDataPage = () => {
  setStatusSave(true);
  if (listBanner.some((banner) => banner === null)) return;
  setStatusSave(false);
  setIsShowPopupConfirm(true);
 };

 const savePage = () => {
  document.body.style.overflow = "auto";
  setIsShowPopupConfirm(false);
  setIsShowPopup(false);
  setStatusEditPage(false);

  postStore.updatePopupBanners(
   Object({
    isActive: statusEditTime,
    showTime: timeShowPopup,
    popupBannersList: listBanner.map((banner) => banner._id),
   })
  );
  postStore.getPopupBanners();
 };

 const PopupBannerItemBox = styled.div`
  margin: 0.2em;
  width: 250px;
  height: 300px;
  overflow: hidden;
  background: #3e3e3e;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  font-size: 1em;
  letter-spacing: 2px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  font-size: 9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.4);
 `;
 const PopupBannerItemText = styled.p`
  font-size: 9rem;
 `;

 return (
  <div className="">
   <div className="w-full h-1 bg-slate-400 rounded-md"></div>
   <div className="">
    <div className="d-flex align-items-center justify-content-between">
     <h4 className="m-0 p-0 w-100">Popup Banner Items</h4>

     {!statusEditPage ? (
      <button
       type="button"
       className="btn btn-light p-1"
       onClick={() => {
        setStatusEditPage(true);
       }}
      >
       <i className={`bx bx-edit w-100`}></i>
      </button>
     ) : (
      <div className="d-flex align-items-center justify-content-end w-100 gap-2">
       <button
        type="button"
        className="btn btn-sm btn-danger"
        onClick={() => {
         reloadData();
         setIsShowPopupConfirm(false);
         setIsShowPopup(false);
         setStatusEditPage(false);
        }}
       >
        Сancel
       </button>
       <button
        type="button"
        className="btn btn-sm btn-success"
        onClick={() => {
         validDataPage();
        }}
       >
        Save
       </button>
      </div>
     )}
    </div>

    <hr />
    <div className={`${!statusEditPage ? "no-events" : null}`}>
     <div className="align-items-center justify-content-start ">
      <div className="w-25 mb-4 d-flex flex-column align-items-start justify-content-start gap-1">
       <div className="form-check form-switch d-flex align-items-center gap-2">
        <input
         className="form-check-input mb-1 cursor-pointer"
         type="checkbox"
         role="switch"
         id="flexSwitchCheckDefault"
         checked={statusEditTime}
         onChange={() => {
          setStatusEditTime(!statusEditTime);
         }}
        />
        <label
         className="form-check-label fs-6"
         htmlFor="flexSwitchCheckDefault"
        >
         Work of popup banners( Appearance time (s)):
        </label>
       </div>
       <div
        className={`input-container ${!statusEditTime ? "no-events" : null}`}
       >
        <MyInput
         type="text"
         placeholder=""
         icon="time"
         value={timeShowPopup}
         void={(e) => {
          setTimeShowPopup(e.target.value);
         }}
        />
       </div>
      </div>
     </div>

     <div className="card">
      <div className="column-lg-8">
       <div className="d-flex justify-content-center flex-wrap py-4">
        {listBanner.map((e, i) => {
         return e ? (
          <div
           key={e._id + i}
           className="col-lg-4 d-flex justify-content-center"
          >
           <div
            className="d-flex flex-column justify-content-center"
            onClick={() => changeBanner(i)}
           >
            <PopupBannerItem title={e.title} img={e.mainImg} />
           </div>
          </div>
         ) : (
          <div key={i} className="col-lg-4 d-flex justify-content-center">
           <div className="" onClick={() => changeBanner(i)}>
            <PopupBannerItemBox
             className={`${!e && statusSave ? "card-invalid" : null}`}
            >
             <PopupBannerItemText>+</PopupBannerItemText>
            </PopupBannerItemBox>
           </div>
          </div>
         );
        })}
       </div>
      </div>
     </div>
    </div>
   </div>
   {isShowPopup ? (
    <PopupModalBanner
     actionShowPopup={() => {
      document.body.style.overflow = "auto";
      setIsShowPopup(!isShowPopup);
     }}
     actionEditBanner={(id) => {
      document.body.style.overflow = "auto";
      handleChooseBanner(id);
     }}
    />
   ) : (
    false
   )}
   {isShowPopupConfirm ? (
    <PopupBannerConfirm
     actionShowPopup={(status) => {
      status ? savePage() : setIsShowPopupConfirm(false);
      document.body.style.overflow = "auto";
     }}
    />
   ) : (
    false
   )}
  </div>
 );
};

export default observer(PopupBannerList);
