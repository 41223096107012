import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../index";

interface SideButtonProps {
 text: string;
 link: string;
 active: boolean;
 id: number;
 icon: React.ReactNode;
}

const SideButton: FC<SideButtonProps> = (props) => {
 const { store } = useContext(Context);

 const handleClick = () => {
  store.setSideBarActiveID(props.id); // Встановлення активного ID при кліку
 };

 return (
  <Link
   to={props.link}
   onClick={handleClick}
   className={props.active ? "active_btn" : ""}
  >
   <button
    type="button"
    aria-expanded="false"
    className={`${
     props.active ? "active_btn" : ""
    } btn btn-dark d-flex bg-transparent border-0`}
   >
    <div className="parent-icon">
     <i className={`bx bx-${props.icon}`}></i>
    </div>
    <div className="menu-title">{props.text}</div>
   </button>
  </Link>
 );
};

export default SideButton;
