import React, {
 FC,
 useContext,
 useEffect,
 useMemo,
 useRef,
 useState,
} from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import BannerItem from "../components/Banners/BannerItem";
import Loading from "../components/UI/Loading/Loading";

interface Banner {
 title: string;
 _id: string;
 isActive: boolean;
 link: string;
 description: string;
 seoName: string;
 post: string;
}

const BannerList: FC = () => {
 const { postStore } = useContext(Context);
 const [page, setPage] = useState(1);
 const [totalPages, setTotalPages] = useState<number[]>([]);
 const [loading, setLoading] = useState<boolean>(true);
 const limit = 10;
 const parentRef = useRef<any>(null);

 const pages = useMemo(() => {
  const pagesArray = [];
  for (let i = 0; i < postStore.bannerPages; i++) {
   pagesArray.push(i + 1);
  }
  setTotalPages(pagesArray);
  return pagesArray;
 }, [postStore.bannerPages]); // виправлено на postStore.bannerPages замість postStore.pages

 useEffect(() => {
  const fetchBanners = async () => {
   setLoading(true);
   try {
    await postStore.getBanners(page, limit);
   } catch (error) {
    console.error("Error fetching banners:", error);
   } finally {
    setLoading(false);
   }
  };

  fetchBanners();
 }, [page]);

 const handleClickPage = (i: number) => {
  setPage(i);
 };

 return (
  <div>
   <div className="w-full h-1 bg-slate-400 rounded-md"></div>
   <div>
    Banners List
    <hr />
    <div className="card">
     <div className="card-body">
      {loading ? (
       <Loading />
      ) : (
       <table className="table mb-0 table-dark table-striped">
        <thead>
         <tr>
          <th scope="col">#</th>
          <th style={{ width: "130px!important" }} scope="col">
           Main Img
          </th>
          <th scope="col">Title</th>
          <th scope="col">Show</th>
          <th scope="col"></th>
          <th scope="col"></th>
         </tr>
        </thead>
        <tbody ref={parentRef}>
         {postStore.banners.map((e, i) => (
          <BannerItem
           title={e.title}
           priority={e.priority}
           id={e._id}
           seoName={e.seoName}
           isActive={e.isActive}
           description={e.description}
           post={e.post}
           link={e.link}
           key={i}
           mainImg={e.mainImg}
          />
         ))}
        </tbody>
       </table>
      )}
      <nav aria-label="...">
       <ul className="pagination mt-3">
        <li className="page-item">
         <span className="page-link" style={{ color: "#12181a" }}>
          i
         </span>
        </li>
        {totalPages.map((e, i) => (
         <li key={i} className="page-item">
          <button
           onClick={() => handleClickPage(i + 1)}
           className={page === i + 1 ? "page-link bg-secondary" : "page-link"}
          >
           {i + 1}
          </button>
         </li>
        ))}
        <li className="page-item">
         <span className="page-link" style={{ color: "#12181a" }}>
          i
         </span>
        </li>
       </ul>
      </nav>
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(BannerList);
