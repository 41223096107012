import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "../../../index";
import { RoutesList } from "../../../routes";

const Category = () => {
 const { storeblog } = useContext(Context);
 const navigate = useNavigate();
 // const categoriesNames = ['Финансы', 'Здоровье', 'Другое'];
 const categoriesNames = ["Finance", "Další"];
 // const categories = ['FINANCE', 'HEALTH', 'ANOTHER' ];
 const categories = ["FINANCE", "ANOTHER"];
 const [activeCategory, setActiveCategory] = useState(null);

 useEffect(() => {
  categories.map((e, i) => {
   if (e == storeblog.selectedCategory) {
    setActiveCategory(i);
   }
  });
 }, [storeblog.selectedCategory]);
 const handleCategoryClick = (e, i) => {
  setActiveCategory(i);
  navigate(RoutesList.MAIN_ROUTER + "category/" + e.toLowerCase());
 };

 const handleAboutUs = () => {
  navigate(RoutesList.ABOUT_US_ROUTER);
 };

 return (
  <div className="main-menu">
   <div className="navbar navbar-expand-lg navbar-light">
    <div id="menu-id">
     <div className="container">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        {categories.map((e, i) => (
         <li className="nav-item" key={i}>
          <button
           key={i}
           onClick={() => handleCategoryClick(e, i)}
           className={
            "nav-link cursor-pointer text-decoration-none" +
            (activeCategory === i ? " active" : "")
           }
          >
           {categoriesNames[i]}
          </button>
         </li>
        ))}
        {/* <li className="nav-item" key="4-about">
         <button
          key="aboutus"
          onClick={() => handleAboutUs()}
          className={"nav-link cursor-pointer text-decoration-none"}
         >
          О нас
         </button>
        </li> */}
       </ul>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(Category);
