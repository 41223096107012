import React, { FC, useContext, useState } from "react";

interface ModalAgreeProps {
 handleAgreement: (e: boolean) => void;
}

const ModalAgree: FC<ModalAgreeProps> = (props) => {
 const setAgreement = (status: boolean) => {
  props.handleAgreement(status);
 };

 return (
  <div className="modal show edit_post " role="dialog">
   <div
    className="modal-dialog w-75 min-vh-100 d-flex flex-column align-items-center justify-content-center"
    role="document"
   >
    <div className="modal-content p-4">
     <div className="d-flex flex-column align-items-center w-100">
      <h3>Save changes?</h3>
      <div className="d-flex justify-content-center w-100 pt-4">
       <button
        onClick={() => setAgreement(false)}
        className="btn btn-danger rounded-md w-25"
       >
        Cancel
       </button>
       <button
        onClick={() => setAgreement(true)}
        className="btn btn-success rounded-md mx-2 w-25"
       >
        Yes
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default ModalAgree;
