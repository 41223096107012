import React, { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

interface PopupBannerItem {
 title: string;
 img: string;
}

const PopupBannerItemBox = styled.div`
 margin: 0.2em;
 width: 250px;
 height: 300px;
 background: #1c1c1c;
 outline: none;
 border: none;
 border-radius: 8px;
 box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
 font-size: 1em;
 letter-spacing: 2px;
 color: #f0f0f0;
 cursor: pointer;
 user-select: none;
 overflow: hidden;
`;

const PopupBannerItemImg = styled.img`
 width: 250px;
 height: 166px;
`;

const PopupBannerItemText = styled.p`
 padding: 0.5em;
`;

const PopupBannerItem: FC<PopupBannerItem> = (props) => {
 return (
  <PopupBannerItemBox>
   <PopupBannerItemImg src={props.img} />
   <PopupBannerItemText>{props.title}</PopupBannerItemText>
  </PopupBannerItemBox>
 );
};

export default observer(PopupBannerItem);
