import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { observer } from "mobx-react-lite";

const AuthPage: FC = () => {
 const refreshToken = localStorage.getItem("token");
 console.log(refreshToken);
 if (refreshToken) {
  return <Navigate to="/admin/" replace />;
 }
 return (
  <div className="col  pt-5 min-vh-100 d-flex align-items-center ">
   <div className="col-lg-6 mx-auto">
    <LoginForm />
   </div>
  </div>
 );
};

export default observer(AuthPage);
