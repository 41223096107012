import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";

export const APP_MAIN_URL =
 window.location.hostname == "localhost"
  ? `http://localhost:5002`
  : `https://${window.location.hostname}`;
// export const APP_MAIN_URL = `https://${window.location.hostname}`;
// export const APP_MAIN_URL = `http://localhost:5002`;
export const API_URL = `${APP_MAIN_URL}/api`;

const $api = axios.create({
 withCredentials: true,
 baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
 config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
 return config;
});

$api.interceptors.response.use(
 (config) => {
  return config;
 },
 async (error) => {
  const originalRequest = error.config;
  if (error.response.status == 401 && error.config && !error.config._isRetry) {
   originalRequest._isRetry = true;
   try {
    const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {
     withCredentials: true,
    });
    localStorage.setItem("token", response.data.accessToken);
    return $api.request(originalRequest);
   } catch (e) {}
  }
  throw error;
 }
);

export default $api;
