import React, { FC, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { Navigate, Route, Routes } from "react-router-dom";
import { privateRoutes, RoutesList } from "./index";

const PrivateAppRouter: FC = () => {
 const { store } = useContext(Context);

 const refreshToken = localStorage.getItem("token");

 useEffect(() => {
  if (!refreshToken) {
   store.checkIsAuth();
  }
 }, [store, refreshToken]);

 if (!refreshToken && !store.isAuth) {
  return <Navigate to="/login" replace />;
 }

 return (
  <Routes>
   {privateRoutes.map((route) => (
    <Route path={route.path} Component={route.component} key={route.path} />
   ))}
   <Route
    path="/admin"
    element={<Navigate replace to={RoutesList.POSTS_LIST_ROUTER} />}
   />
  </Routes>
 );
};

export default observer(PrivateAppRouter);
