import React, { useContext, useEffect, useState } from "react";
import {
 Navigate,
 useLocation,
 useNavigate,
 useParams,
} from "react-router-dom";
import { getOnePosts } from "../../http/postsApi";
import { observer } from "mobx-react-lite";
import ArticleItem from "./ArticleItem";
import { Context } from "../../../index";

const PostPage = () => {
 const { storeblog } = useContext(Context);
 const [post, setPost] = useState("");
 const [title, setTitle] = useState("");
 // const [seoName, setSeoName] = useState('');

 const { id, seoName } = useParams();
 const params = useParams();
 const location = useLocation();
 const navigate = useNavigate();

 localStorage.setItem("articleSeoName", seoName);

 useEffect(() => {
  const fetchOnePost = async () => {
   try {
    const data = await getOnePosts(id, storeblog.urlParams);
    setTitle(data.title);
    // setSeoName(data.seoName);
    articleLinkUpdate(data.post);
   } catch (e) {
    setPost("");
   }
  };
  fetchOnePost();
 }, [id, storeblog.urlParams]);

 const articleLinkUpdate = (post) => {
  const regex = /href=(["'])(.*?)\1/g;
  let modifiedHtml = post.replace(regex, function (match, quote, hrefValue) {
   return "href=" + quote + hrefValue + storeblog.urlParams + quote + "";
  });
  setPost(modifiedHtml);
 };

 return (
  <div>
   <ArticleItem articleSeoName={seoName} id={id} post={post} title={title} />
  </div>
 );
};

export default observer(PostPage);
