import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";

import PrivateAppRouter from "./routes/PrivateAppRouter";

const AuthPanel: FC = () => {
 return (
  <div className="flex" id="html">
   <div className="">
    <SideBar />
   </div>
   <div className="p-4 col">
    <div>
     <Header />
    </div>
    <div className="page-wrapper">
     <div className="page-content">
      <PrivateAppRouter />
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(AuthPanel);
