import { observer } from "mobx-react-lite";
import "./feedbackForm.css";
import { useState } from "react";

const FeedbackForm = () => {
 const [showSubscribe, setShowSubscribe] = useState(true);
 const [emailValue, setEmailValue] = useState("");

 const userSubscribe = () => {
  setShowSubscribe(false);
  setEmailValue("");
 };

 return (
  <div className="feedbackForm">
   <div className="feedbackForm__container">
    {showSubscribe ? (
     <>
      <h3>Přihlaste se k odběru newsletteru!</h3>
      <div className="input-group mb-3">
       <input
        type="text"
        className="form-control"
        placeholder="Recipient's username"
        value={emailValue}
        onChange={(e) => setEmailValue(e.target.value)}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
       />
       <span className="input-group-text" id="basic-addon2">
        @example.com
       </span>
      </div>
      <button
       onClick={() => userSubscribe()}
       className="btn btn-danger w-100 text-uppercase"
      >
       odeslat
      </button>
     </>
    ) : (
     <h3>
      Děkujeme, že jste se přihlásili k odběru! <br /> Nyní se můžete na
      dozvědět nejnovější zprávy na jednom z prvních míst!
     </h3>
    )}
   </div>
  </div>
 );
};

export default observer(FeedbackForm);
