import React, { FC } from "react";

import { observer } from "mobx-react-lite";

interface ModalProps {
 actionShowPopup: (status: boolean) => void;
}

const PopupModalBanner: FC<ModalProps> = (props) => {
 return (
  <div
   className="modal show"
   onClick={() => {
    props.actionShowPopup(false);
   }}
  >
   <div
    className="modal-dialog center"
    onClick={(e) => {
     e.stopPropagation();
    }}
   >
    <div className="modal-content">
     <div
      className=" d-flex flex-column align-items-center w-100 rounded"
      style={{ boxShadow: "0 0 12px 4px #5c5c5c" }}
     >
      <div className="modal-header d-flex align-item-center justify-content-space w-100 p-2 px-3">
       <h4 className="m-0">Confirm</h4>
       <button
        type="button"
        className="btn btn-dark btn-sm active border border-white "
        onClick={() => {
         props.actionShowPopup(false);
        }}
       >
        <span aria-hidden="true">&times;</span>
       </button>
      </div>
      <h2 className="m-0 mt-4 py-4">Are you sure?</h2>
      <div className="d-flex align-items-center justify-content-center w-100 gap-2 py-4">
       <button
        type="button"
        className="btn btn-m btn-danger"
        onClick={() => {
         props.actionShowPopup(false);
        }}
       >
        Сancel
       </button>
       <button
        type="button"
        className="btn btn-m btn-success"
        onClick={() => {
         props.actionShowPopup(true);
        }}
       >
        Save
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default observer(PopupModalBanner);
