import { $host } from "./index";

export const getBanners = async (page, limit) => {
 const { data } = await $host.get(`/api/banner/getBanners/${page}/${limit}`);
 return data;
};
export const getPopupBanners = async () => {
 const { data } = await $host.get(`/api/popup/getPopupBanner`);
 return data;
};

export const getBackBtnLink = async () => {
 const { data } = await $host.get(`/api/backBtn/getBackBtnLink`);
 return data;
};

export const sendUserAction = async (banner, article, action, date) => {
 const { data } = await $host.get(
  `/api/userAction/addUserAction/?banner=${banner}&article=${article}&action=${action}&date=${date}`
 );
 return data;
};

export const getBannerStats = async (startDate, endDate) => {
 try {
  return await $host.post(`/api/userAction/getBannerStats/`, {
   startDate,
   endDate,
   _t: new Date().getTime(),
  });
 } catch (e) {
  console.error("Помилка запиту:", e.message || e);
  throw e;
 }
};
