import React from "react";

import BlogPage from "../pages/BlogPage";
import BannerAddPage from "../pages/BannerAddPage";
import BannerList from "../pages/BannerList";
import PostsList from "../pages/PostsList";
import OneSignalFormToken from "../components/OneSignalFormToken/OneSignalFormToken";

import Main from "../publickArticle/components/Main";
import PostPage from "../publickArticle/components/Posts/PostPage";
import CategoryPage from "../publickArticle/components/CategoryPage";
import PopupBannerList from "../pages/PopupBannerPage";
import BackBtnLinkPage from "../pages/BackBtnLinkPage";
import AboutUs from "../publickArticle/components/AboutUs/AboutUs";
import BannerStatisticPage from "../pages/BannerStatistic";

export interface IRoute {
 path: string;
 component?: React.ComponentType;
}

export enum RoutesList {
 MAIN_ROUTER = "/",
 POST_ROUTER = "/post",
 AUTH_ROUTER = "/login",
 BLOG_ROUTER = "/admin/blog",
 CREATE_BANNER_ROUTER = "/admin/createBanner",
 BANNERS_LIST_ROUTER = "/admin/bannersList",
 POSTS_LIST_ROUTER = "/admin/postsList",
 POPUP_BANNER_ROUTER = "/admin/popupBanner",
 ONE_SIGNAL_EDIT_TOKEN_ROUTER = "/admin/editOneSignalToken",
 BACK_BTN_URL_ROUTER = "/admin/backBtnLink",
 CATEGORY_ROUTER = "/category",
 ABOUT_US_ROUTER = "/about-us",
 BANNER_STATISTIC_ROUTER = "/admin/bannerStatistic",
}

export const publicRoutes: IRoute[] = [
 { path: RoutesList.MAIN_ROUTER, component: Main },
 { path: RoutesList.POST_ROUTER + "/:seoName" + "/:id", component: PostPage },
 { path: RoutesList.CATEGORY_ROUTER + "/:category", component: CategoryPage },
 { path: RoutesList.ABOUT_US_ROUTER, component: AboutUs },
];

export const privateRoutes: IRoute[] = [
 { path: RoutesList.BLOG_ROUTER, component: BlogPage },
 { path: RoutesList.CREATE_BANNER_ROUTER, component: BannerAddPage },
 { path: RoutesList.BANNERS_LIST_ROUTER, component: BannerList },
 { path: RoutesList.POSTS_LIST_ROUTER, component: PostsList },
 {
  path: RoutesList.ONE_SIGNAL_EDIT_TOKEN_ROUTER,
  component: OneSignalFormToken,
 },
 { path: RoutesList.BACK_BTN_URL_ROUTER, component: BackBtnLinkPage },
 { path: RoutesList.POPUP_BANNER_ROUTER, component: PopupBannerList },
 { path: RoutesList.BANNER_STATISTIC_ROUTER, component: BannerStatisticPage },
];
